import React from 'react';
import classes from './about.module.css';
import imgg from '../../../assets/imgs/Dental cabinet bg.png';
import { useTranslation } from 'react-i18next';

const About = () => {
   const [t, i18n] = useTranslation();

   return (
      <div className={classes.containerAbout}>
         <img src={imgg} alt="" />
         <div className={classes.aboutContianer}>
            <h1>{t('about-us')}</h1>
            <p>{t('about_homePage_Paragraph')}</p>
         </div>
      </div>
   )
};

export default About;