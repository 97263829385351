import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import classes from './gallary.module.css';

import DrAhmedAyman from '../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/01.jpg';
import DrSamerFayez from '../../assets/imgs/Doctors Gallery/Dr Samer Fayez/1.jpg';
import DrBoutros from '../../assets/imgs/Doctors Gallery/Dr. Boutros/1.jpg';
import DrIlhamFawzy from '../../assets/imgs/Doctors Gallery/Dr. Ilham Fawzy/01.jpg';
import DrKhaledAbdelghafar from '../../assets/imgs/Doctors Gallery/Dr. Khaled Abdelghafar/1.jpg';
import DrRamiMaherReflect from '../../assets/imgs/Doctors Gallery/Dr. Rami Maher Reflect/01.jpg';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Gallery = () => {
   const [t, i18n] = useTranslation();

   return (
      <div className={classes.bg}>
         <header className={classes.header}>
            <h1>{t('gallary')}</h1>
            <Container fluid>
               <Row>
                  <Col lg={3}>
                     <div className={classes.clientContent}>
                        <Link to="/drAhmedAyman">
                           <h6>Dr Ahmed Ayman</h6>
                           <img src={DrAhmedAyman} alt="" />
                        </Link>
                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.clientContent}>
                        <Link to="/drSamerFayez">
                           <h6>Dr Samer Fayez</h6>
                           <img src={DrSamerFayez} alt="" />
                        </Link>
                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.clientContent}>
                        <Link to="/drBoutros">
                           <h6>Dr. Boutros Maurice</h6>
                           <img src={DrBoutros} alt="" />
                        </Link>
                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.clientContent}>
                        <Link to="/drIlhamFawzy">
                           <h6>Dr. Ilham Fawzy</h6>
                           <img src={DrIlhamFawzy} alt="" />
                        </Link>
                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.clientContent}>
                        <Link to="/drKhaledAbdelghafar">
                           <h6>Dr. Khaled Abdelghafar</h6>
                           <img src={DrKhaledAbdelghafar} alt="" />
                        </Link>
                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.clientContent}>
                        <Link to="/drRamiMaherReflect">
                           <h6>Dr. Rami Maher</h6>
                           <img src={DrRamiMaherReflect} alt="" />
                        </Link>
                     </div>
                  </Col>
               </Row>
            </Container>
         </header>
      </div>
   )
};

export default Gallery;