import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ContactUs from "./components/contactUs/ContactUs";
import ClientsPage from "./pages/ClientsPage";
import GallaryPage from "./pages/GallaryPage";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";

import DrAhmedAyman from "./components/sliders/drAhmedAyman/DrAhmedAyman";
import DrSamerFayez from "./components/sliders/drSamerFayez/DrSamerFayez";
import DrBoutros from "./components/sliders/drBoutros/DrBoutros";
import DrIlhamFawzy from "./components/sliders/drIlhamFawzy/DrIlhamFawzy";
import DrKhaledAbdelghafar from "./components/sliders/drKhaledAbdelghafar/DrKhaledAbdelghafar";
import DrRamiMaherReflect from "./components/sliders/drRamiMaherReflect/DrRamiMaherReflect";

const App = () => {
  const [contactModal, setContactModal] = useState(false);
  const [contactModal2, setContactModal2] = useState(false);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage setContactModal2={setContactModal2} />} />
          <Route path="/Clients" element={<ClientsPage setContactModal2={setContactModal2} />} />
          <Route path="/gallery" element={<GallaryPage setContactModal2={setContactModal2} />} />
          <Route path="/aboutus" element={<AboutUsPage setContactModal2={setContactModal2} />} />

          <Route path="/DrAhmedAyman" element={<DrAhmedAyman />} />
          <Route path="/drSamerFayez" element={<DrSamerFayez />} />
          <Route path="/drBoutros" element={<DrBoutros />} />
          <Route path="/drIlhamFawzy" element={<DrIlhamFawzy />} />
          <Route path="/drKhaledAbdelghafar" element={<DrKhaledAbdelghafar />} />
          <Route path="/drRamiMaherReflect" element={<DrRamiMaherReflect />} />
        </Routes>
        <ContactUs
          setContactModal2={setContactModal2}
          contactModal2={contactModal2}
          setContactModal={setContactModal}
          contactModal={contactModal}
        />
      </Router>
    </>
  );
};

export default App;
