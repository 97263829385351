import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap';
import NavbarComponent from '../components/home/navbar/NavbarComponent';
import { Element } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import Gallary from '../components/gallary/Gallery';
import Footer from '../components/footer/Footer';

const GallaryPage = ({ setContactModal2 }) => {
   const location = useLocation();

   useEffect(() => {
      if (location.hash) {
         let elem = document.getElementById(location.hash.slice(1));
         if (elem) {
            elem.scrollIntoView({ behavior: "smooth" });
         }
      } else {
         window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
   }, [location]);

   return (
      <Element id='GallaryPage'>
         <Container fluid >
            <div style={{ background: "#82c8bd", borderRadius: '70px' }}>
               <NavbarComponent setContactModal2={setContactModal2} />
               <Gallary />
            </div>
         </Container>
         <Footer />
      </Element>
   )
};

export default GallaryPage;