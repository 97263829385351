import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./footer.module.css";
import img2 from "../../assets/imgs/Dental Cabinet png.png";
import { Link } from "react-router-dom";
import facebook from "../../assets/imgs/Icons/Asset 1.png";
import instagram from "../../assets/imgs/Icons/Asset 2.png";
import twitter from "../../assets/imgs/Icons/Asset 3.png";
import whatsApp from "../../assets/imgs/Icons/Asset 6.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t, i18n] = useTranslation();

  return (
    <div className={classes.footer}>
      <Container fluid>
        <Row>
          <Col xs={4}>
            <div className={classes.logoConainer}>
              <a href="https://dentalcabinetegypt.com/" target="_blank">
                <img src={img2} alt="" />
              </a>
            </div>
          </Col>
          <Col xs={4} className={classes.colLinks}>
            <div
              className={classes.links}
              style={{
                direction: `${t("home") === "الرئيسية" ? "rtl" : "ltr"}`,
              }}
            >
              <Link to="/">{t("home")}</Link>
              <Link to="/aboutus">{t("about-us")}</Link>
              <Link to="/gallery">{t("gallary")}</Link>
              <Link to="/clients">{t("clients")}</Link>
            </div>
          </Col>
          <Col xs={4} className={classes.colMedia}>
            <div className={classes.media}>
              <img src={facebook} alt="" />
              <img src={instagram} alt="" />
              <img src={twitter} alt="" />
              <img src={whatsApp} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      <div className={classes.footerCopyright}>
        <p>
          Copyright &copy;{" "}
          <a target="_blank" href="https://teqneia.com/">
            TEQNEIA
          </a>{" "}
          2015-2022{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
