import React from 'react';
import classes from './aboutUs.module.css';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DrRamiMaherReflect from '../../assets/imgs/Doctors Gallery/Dr. Rami Maher Reflect/01.jpg';
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "English",
    Country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    Country_code: "sa",
    // dir: "rtl",
  },
];


const AboutUs = () => {
  const [t, i18n] = useTranslation();
  const currentLangsCode = cookies.get("i18next") || "en";
  const currentLangs = languages.find((l) => l.code === currentLangsCode);

  return (
    <div
    className={classes.bg}
    style={{
      direction: `${currentLangs.name === "English" ? "ltr" : "rtl"}`,
    }}
  >
      <header className={classes.header}>
        <div className={classes.aboutcontent}>
          <p>{t('dentalCabinetFirstParagraph')}</p>
        </div>
        <Row className='flex-md-row flex-column-reverse'>
          <Col lg={6}>
            <div className={classes.featuresContent}>
              <p>{t('dentalCabinetFirstParagraph1')}</p>
              <h3>{t('features_h3')}</h3>
              <p>{t('features_p')}</p>
              <p>{t('features_p_2')}</p>
            </div>
            <div className={classes.advantagesContent}>
              <h3>{t('Advantages_h3')}</h3>
              <div className={classes.ulcontainer}>
                <ul>
                  <li>{t('Advantages_li_1')}</li>
                  <li>{t('Advantages_li_2')}</li>
                  <li>{t('Advantages_li_3')}</li>
                </ul>
              </div>
            </div>
            <p className={classes.testP}>
              {t('AdvantagesTest_p')}
            </p>
          </Col>
          <Col lg={6}>
            <div className={classes.imgContainer}>
              <img src={DrRamiMaherReflect} alt="" />
            </div>
          </Col>
        </Row>
        {/* <div className={classes.featuresContent}>
               <h3>Features</h3>
               <p>Our Cabinet is made of galvanized steel before manufacturing, covered with electrostatic or wood covered with (PVC, POLYLAC, and UV) all of them antacid, anti-chemical and anti-bacterial. The surface plate is made of Corian, Glass, epoxy or up to request.</p>
            </div>
            <div className={classes.advantagesContent}>
               <h3>Advantages</h3>
               <h6>Our cabinets are designed to:</h6>
               <div className={classes.ulcontainer}>
                  <ul>
                     <li>Maximize the available space and ensure efficiency.</li>
                     <li>Offers quality, infection prevention and control.</li>
                     <li>Have convenient access surfaces.</li>
                     <li>Allow the dental team to remain in close proximity to the client. </li>
                  </ul>
                  <div className={classes.imgContainer}>
                     <img src={DrKhaledImg} alt="" />
                  </div>
               </div>
               <p>We have a selection of medical-grade cabinets and dental equipment that are manufactured with the highest quality material to suit any requirements and reflect your standards.</p>
            </div> */}
      </header>
    </div>

  )
}

export default AboutUs