import React, { useState } from 'react';
import { MdOutlineClose } from "react-icons/md";
import Slider from 'react-slick';
import { Container } from 'react-bootstrap';

import './cleopatra.css';
import classes from './cleopatra.module.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from 'react-router-dom';

import c1 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/01.jpg'
import c2 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/02.jpg'
import c3 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/03.jpg'
import c4 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/04.jpg'
import c5 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/05.jpg'
import c6 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/06.jpg'
import c7 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/07.jpg'
import c8 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/08.jpg'
import c9 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/09.jpg'
import c10 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/10.jpg'
import c11 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/11.jpg'
import c12 from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/12.jpg'

const imgs = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12];

const DrAhmedAyman = () => {
   const [slideIndex, setSlideIndex] = useState(0);

   const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      beforeChange: (current, next) => setSlideIndex(next),
      centerMode: true,
      cssEase: "linear",
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   return (
      <div className={classes.cleopatra}>
         <div className={classes.closeButton}>
            <Link to='/gallery'>
               <MdOutlineClose />
            </Link>
         </div>
         <Container
          >
            <h2>Dr Ahmed Ayman</h2>
            <div className='slider'>
               <Slider {...settings}>
                  {
                     imgs.map((img, index) => (
                        <div key={index} className={index === slideIndex ? 'slide slide-active' : 'slide'}>
                           <img src={img} alt="" />
                        </div>
                     ))
                  }
               </Slider>
            </div>
         </Container>
      </div>

   )
};

export default DrAhmedAyman;