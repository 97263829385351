import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import classes from './clients.module.css';
import img1 from '../../../assets/imgs/Clients/c1.png';
import img2 from '../../../assets/imgs/Clients/c2.png';
import img3 from '../../../assets/imgs/Clients/c3.png';
import img4 from '../../../assets/imgs/Clients/c4.jpg';
import img5 from '../../../assets/imgs/Clients/c5.png';
import img6 from '../../../assets/imgs/Clients/c6.png';
import img7 from '../../../assets/imgs/Clients/c7.png';
import { useTranslation } from 'react-i18next';

const Clients = () => {
   const [t, i18n] = useTranslation();

   return (
      <div className={classes.clients}>
         <h1>{t('clients')}</h1>
         <div className={classes.clientsConent}>
            <Container fluid>
               <Row>
                  <Col lg={3}>
                     <div className={classes.imgContainer}>
                        <img src={img4} alt="" />
                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.imgContainer}>
                        <img src={img5} alt="" />
                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.imgContainer}>
                        <img src={img3} alt="" />
                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.imgContainer}>
                        <img src={img2} alt="" className={classes.img2} />
                     </div>
                  </Col>
               </Row>
               <Row className={classes.row2}>
                  <Col lg={3}>
                     <div className={classes.imgContainer1}>

                        <img src={img1} alt="" />
                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.imgContainer1}>
                        <img src={img6} alt="" />

                     </div>
                  </Col>
                  <Col lg={3}>
                     <div className={classes.imgContainer1}>
                        <img src={img7} alt="" />

                     </div>
                  </Col>
               </Row>
            </Container>
         </div>
      </div>
   )
};

export default Clients;