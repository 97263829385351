import React, { useEffect, useState } from 'react';
import classes from './header.module.css';
import imgDental from '../../../assets/imgs/Dental secrets Bg.png';
import imgDental1 from '../../../assets/imgs/(Dental Secrets) Gallery img 5.png';
import logo from '../../../assets/imgs/Dental Secrets Png.png';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [t, i18n] = useTranslation();

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [width]);

  return (
    <div className={classes.bg}>
      <header className={classes.header}>
        {width < breakpoint ? <img className={classes.imgBg} src={imgDental1} alt="" /> : <img className={classes.imgBg} src={imgDental} alt="" />}
        <div className={classes.headerContent}>
          <h1>{t('homeHeader_h1')}</h1>
        </div>
        <div className={classes.imgLogo}>
          <img src={logo} alt="" />
        </div>
      </header>
    </div>
  )
};

export default Header;