import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classes from './client.module.css'

const Client = () => {
  const [t, i18n] = useTranslation();

  return (
    <div className={classes.bg}>
      <header className={classes.header}>
        <h1>{t('clients')}</h1>
        <Container fluid>
          <Row>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Khaled Abd Elghafar</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Tarik Salah Elden Hussien</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Mohamed Diaa</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Rami Maher Ghali</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Hossam Elmalah</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Elham Fawzy</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Ali El Helaly</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Salah Abdelfatah</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Mona Riad</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Marwa El-Kasaby</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Abobakr Hashem</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. TarekAbd Elsamad</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr.  Islam Tarik Abbas</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Prof. Dr. Mona Shoib</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Hazim El Beltagy</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Samer Mustafa</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Khaled Al Ashkar</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Tarek AL Ashkar</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Karim Abo Elenen</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Hisham Kotb</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Yamen El- Genedy (The Dental Studio)</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Ahmed Atef (The Dental Studio)</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Ahmed Fayyad</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Amgad Ihab Elsaid</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Mohamed Mustafa</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Shimaa Elsabah</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Emad Meawad</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Manal El-Namarway</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Nader ElZohery</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Gamil Boshra Hanna</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Mohammed Gohhar</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Anwar Zaki</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Samia Hanna</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Mahmoud Mahgoub</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Ahmed Ali</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Sherif Gad</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Ahmed El Amreity</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Omar Elsadat</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Mohammed Ahmed Abo Eleila</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Hamed Elmegharbl</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Ali Abdallah</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Ahmed Adel</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Moustafa Gamil</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Amr Elkamah</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Amr Temraz</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Mohamed Sherif</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Hisham Shaltout</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dr. Ahmed Ayman</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Cleopatra Hospital</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Dental Clinics</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Ain shams university</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Outpatient Clinics</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Laser laboratory</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Continuous Education Lab</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Credit Hours Clinics</h6>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.clientContent}>
                <h6>Sterilization Unit</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </div>
  )
};

export default Client;