import React, { Component } from "react";
import Slider from "react-slick";
import './gallery.css';
import classes from './gallery.module.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BsImageFill } from 'react-icons/bs'

import DrAhmedAyman from '../../../assets/imgs/Doctors Gallery/Dr Ahmed Ayman/03.jpg';
import DrSamerFayez from '../../../assets/imgs/Doctors Gallery/Dr Samer Fayez/1.jpg';
import DrBoutros from '../../../assets/imgs/Doctors Gallery/Dr. Boutros/3.jpg';
import DrIlhamFawzy from '../../../assets/imgs/Doctors Gallery/Dr. Ilham Fawzy/01.jpg';
import DrKhaledAbdelghafar from '../../../assets/imgs/Doctors Gallery/Dr. Khaled Abdelghafar/4.jpg';
import DrRamiMaherReflect from '../../../assets/imgs/Doctors Gallery/Dr. Rami Maher Reflect/01.jpg';

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Gallery = () => {
  const [t, i18n] = useTranslation();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className={classes.galleryContainer}>
      <h2> {t('gallary')} </h2>
      <Slider {...settings}>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to='/drAhmedAyman'>
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrAhmedAyman} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to='/drSamerFayez'>
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrSamerFayez} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to='/drBoutros'>
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrBoutros} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to='/drIlhamFawzy'>
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrIlhamFawzy} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to='/drKhaledAbdelghafar'>
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrKhaledAbdelghafar} alt="" />
        </div>
        <div className={classes.imgContainer}>
          <div className={classes.linkContainer}>
            <Link to='/drRamiMaherReflect'>
              <BsImageFill className={classes.icon} />
            </Link>
          </div>
          <img src={DrRamiMaherReflect} alt="" />
        </div>
      </Slider>
    </div>
  );
};
export default Gallery;